import styled from 'styled-components';

import { mq } from '../utils/helpers';

export const OfferSectionArticles = styled.div`
  line-height: 1.2;
  margin-bottom: 5.625rem;
  margin-top: 6.25rem;
  text-align: center;
  
  ${mq.mq480`
    margin-top: 3.25rem;
    p {
      font-size: 1.25rem;
    }
  `}
`;
