import styled from 'styled-components';

import { mq } from '../utils/helpers';

export const FooterContainer = styled.div`
  display: flex;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  
  a {
    color: white;
    
    &:hover {
      font-weight: bold;
    }
  }
  
  ${mq.mq960`
    flex-direction: column
  `}
`;
