import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import {
  Paragraph,
  SingleArticlePaper,
} from '../styled';

export class SingleFilterArticle extends PureComponent {
  static propTypes = {
    article_heading: PropTypes.string,
    article_content: PropTypes.string,
  };

  static defaultProps = {
    article_heading: '',
    article_content: '',
  };

  render() {
    const {
      article_heading,
      article_content,
    } = this.props;

    return (
      <SingleArticlePaper>
        <Typography
          component="h3"
          dangerouslySetInnerHTML={{ __html: article_heading }}
          variant="h3"
        />
        <Paragraph
          dangerouslySetInnerHTML={{ __html: article_content }}
          marginTop="1.5rem"
          opacity=".6"
          size="3"
        />
      </SingleArticlePaper>
    );
  }
}
