import styled from 'styled-components';

export const SideNavList = styled.ul`
  font-size: 1.875rem;
  list-style: none;
  
  li + li {
    margin-top: .5rem;
  }
  
  a {
    color: inherit;
    text-decoration: none;
    transition: color .15s ease;
    
    &:hover {
      color: ${props => props.theme.colors.red};
    }
  }
`;
