import styled from 'styled-components';

import { mq } from '../utils/helpers';

export const HeroContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  
  ${mq.mq960`
    height: 50vh;
  `}
`;
