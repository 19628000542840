import React from 'react';
import styled from 'styled-components';
import { StyledTextButton} from './StyledTextButton';
import { mq } from '../utils/helpers';

export const StyledLangButton = styled(({ spaceTop, ...rest }) => {
  return <StyledTextButton {...rest} />
})`
  && {
    margin-top: ${props => (props.spaceTop && `${props.spaceTop}rem`)};
    overflow: hidden;
    position: relative;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    color: ${props=> (props.active?'#0043FF':'#ffffff')};

    span {
      z-index: 2;
    }
    
     ${mq.mq480`
      display:none;
    `}

  }
  
`;
