import styled from 'styled-components';

import { mq } from '../utils/helpers';

export const HeroContainer = styled.div`
  align-items: center;
  display: flex;
  max-height: 100vh;
  max-width: 100%;
  margin-top: ${props => props.theme.dimensions.topBreath};
  position: relative;
  
`;
