import styled from 'styled-components';
import Paper from '@material-ui/core/Paper/Paper';

import { mq } from '../utils/helpers';

export const SingleArticlePaper = styled(Paper)`
  && {
    background-color: transparent;
    box-shadow: none;
    max-width: 30%;
    padding: 40px 0;

    ${mq.mq960`
      margin: 0 auto;
      max-width: 320px;
      width: 100%;
    `}
  }
`;
