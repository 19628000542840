import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Typography from '@material-ui/core/Typography';

import { ImageBackground } from '../components/ImageBackground';
import { SlideDownIcon } from '../components/SlideDownIcon';
import {
  Container,
  HeroContainer,
  HeroContentWrapper,
  Paragraph,
  StyledButton,
} from '../styled';

export class HeroSection extends PureComponent {


  render() {
    const {
      children,
    } = this.props;

    return (
      <React.Fragment>
            <ImageBackground />
            <HeroContentWrapper>
              {children}
            </HeroContentWrapper>
      </React.Fragment>
    );
  }
}
