import styled from 'styled-components';

import { mq } from '../utils/helpers';

export const GuideParagraphContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 12rem;
  padding: 2rem;
  position: relative;
  text-align: center;
  
  p {
    margin-bottom: 0;
    opacity: .8;
    position: relative;
    z-index: 3;
  }
  
  ${mq.mq600`
    min-height: 21rem;

    &:after {
      color: rgba(0,0,0, .15);
      content: '${props => props.content}';
      left: 0;
      font-size: 18rem;
      opacity: 1;
      position: absolute;
      top: 50%;
      width: 100%;
      z-index: 1;
    }
  `}
    
  ${mq.mq480`
    padding: 6rem 1rem;
  `}
`;
