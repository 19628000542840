import styled from 'styled-components';

import { mq } from '../utils/helpers';

export const SectionContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 0rem -1.5rem;
  
  ${mq.mq600`
    flex-direction: column;
  `}

`;
