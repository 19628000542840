import { createMuiTheme } from '@material-ui/core/styles';
import { hexToRgba } from './helpers';

import {
  COLORS,
  DIMENSIONS,
  PARAGRAPH_SIZES,
  Z_INDEX,
} from './constants';

export const variables = {
  colors: COLORS,
  dimensions: DIMENSIONS,
  paragraphSizes: PARAGRAPH_SIZES,
  zIndex: Z_INDEX,
};

export const muiTheme = createMuiTheme({
  palette: {
    type: 'dark',
    background: {
      default: COLORS.black,
    },
    text: {
      primary: COLORS.white,
    },
    primary: {
      main: COLORS.blue,
    },
    secondary: {
      main: COLORS.red,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        background: 'inherit',
        borderRadius: DIMENSIONS.buttonBorderRadius,
        fontFamily: 'Galano Grotesque Regular',
        height: '2.75rem',
        opacity: '1',
        padding: '0 2.5rem',
        textTransform: 'none',
      },
      contained: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        color: COLORS.white,
        minWidth: '16rem',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      containedSecondary: {
        backgroundColor: COLORS.blue,
        background: 'none',
        borderRadius: 0,
        width: '100%',
        '&:hover': {
          backgroundColor: COLORS.red,
        },
      },
      outlined: {
        backgroundColor: 'transparent',
        borderColor: COLORS.blue,
        borderStyle: 'solid',
        borderWidth: 1,
        minWidth: '11.625rem',
        transition: 'border-color .4s ease, background-color .4s ease, background .4s ease',
        '&:hover': {
          borderColor: 'transparent',
        },
      },
    },
    MuiCheckbox: {
      root: {
        marginRight: '.5rem',
      },
    },
    MuiDrawer: {
      paper: {
        background: '#0043FF',
        width: '70%',
        maxWidth: '426px',
      },
    },
    MuiExpansionPanel: {
      root: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        paddingBottom: '2rem',
        paddingTop: '1.5rem',
        '&:not(:first-child):before': {
          backgroundColor: COLORS.blue,
          display: 'block !important',
        },
      },
      expanded: {
        '&:before': {
          opacity: 1,
        },
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        paddingLeft: 0,
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        paddingLeft: 0,
      },
    },
    MuiFab: {
      primary: {
        '&:hover': {
          backgroundColor: COLORS.red,
        },
      },
    },
    MuiFormLabel: {
      asterisk: {
        color: COLORS.red,
      },
    },
    MuiIcon: {
      root: {
        height: '1rem',
        width: '1rem',
      },
    },
    MuiIconButton: {
      root: {
        padding: '.75rem',
      },
    },
    MuiInputLabel: {
      outlined: {
        color: `${COLORS.white} !important`,
        fontSize: '1rem',
        transform: 'translate(2.25rem, 1rem) scale(1)',
      },
      shrink: {
        transform: 'translate(1.4rem, -0.6rem) scale(0.75) !important',
      },
    },
    MuiListItem: {
      selected: {
        backgroundColor: `${COLORS.blue} !important`,
      },
    },
    MuiOutlinedInput: {
      root: {
        fontSize: '1rem',
      },
      input: {
        padding: '.825rem 1.5rem',
      },
      notchedOutline: {
        borderColor: `${hexToRgba(COLORS.blue, '.8')} !important`,
        borderRadius: 0,
      },
    },
    MuiMenu: {
      paper: {
        backgroundColor: COLORS.black,
        border: `1px solid ${COLORS.blue}`,
      },
    },
    MuiSelect: {
      icon: {
        fill: COLORS.red,
        right: '.75rem',
        width: '1rem',
      },
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: "'Galano Grotesque Light', 'Helvetica', 'Segoe UI', 'Helvetica Neue', 'Arial', 'sans-serif'",
    fontSize: 16,
    fontWeight: 300,
    /**
     * heading original sizes
     * h1 - 50px
     * h2 - 40px
     * h3 - 30px
     * h4 - 20px
     * h5 - 18px
     */
    h1: {
      fontSize: '3.125rem',
      lineHeight: 1.1,
    },
    h2: {
      fontSize: '2.5rem',
      lineHeight: 1.1,
    },
    h3: {
      fontSize: '1.875rem',
      lineHeight: 1.1,
    },
    h4: {
      fontSize: '1.25rem',
      lineHeight: 1.1,
    },
    h5: {
      fontSize: '1.125rem',
      lineHeight: 1.1,
    },
    a: {
      color: '#0043FF',
    },
  },
});
