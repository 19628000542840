import IconChat from './icon-chat.svg';
import IconFacebook from './icon-facebook.svg';
import IconInstagram from './icon-instagram.svg';
import IconVimeo from './icon-vimeo.svg';
import IconYoutube from './icon-youtube.svg';
import LogoAxn from './logo-axn.svg';
import LogoDhl from './logo-dhl.svg';
import LogoHertz from './logo-hertz.svg';
import LogoLoreal from './logo-loreal.svg';
import LogoOrange from './logo-orange.svg';
import LogoShowmax from './logo-showmax.svg';
import LogoLinkvisuals from './lv-logo.svg';
import LogoLinkvisualsTitled from './lv-title-logo.svg';
import Mute from './mute.svg';
import Unmute from './unmute.svg';

export {
  IconChat,
  IconFacebook,
  IconInstagram,
  IconVimeo,
  IconYoutube,
  LogoAxn,
  LogoDhl,
  LogoHertz,
  LogoLinkvisuals,
  LogoLinkvisualsTitled,
  LogoLoreal,
  LogoOrange,
  LogoShowmax,
  Mute,
  Unmute
};
