import styled from 'styled-components';
import { mq } from '../utils/helpers';

export const AboutColumnContainer = styled.div`
  flex-basis: 48.5%;
  
  ${mq.mq960`
    flex-basis: auto;
  `}
`;
