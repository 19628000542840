import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button/Button';

import { hexToRgba } from '../utils/helpers';
import {
  COLORS,
  DIMENSIONS,
} from '../utils/constants';

export const StyledBoxButton = styled(({ spaceTop, ...rest }) => {
  return <Button {...rest} />
})`
  && {
    margin-top: ${props => (props.spaceTop && `${props.spaceTop}rem`)};
    overflow: hidden;
    position: relative;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    color: ${props=> (props.active?'#0043FF':'#ffffff')};
    background: rgb(0,67,255);
    background: linear-gradient(90deg, rgba(0,67,255,1) 0%, rgba(0,0,0,1) 100%);
    border: #0043FF;
    

    span {
      z-index: 2;
    }

  }
  
`;
