import styled from 'styled-components';

import { COLORS } from '../utils/constants';
import {
  hexToRgba,
  mq,
} from '../utils/helpers';


export const StyledSectionLineSingle = styled.span`
  background: linear-gradient(125deg, ${hexToRgba(COLORS.red, '1')} 10%, ${hexToRgba(COLORS.blue, '1')} 90%);
  border-radius: .1875rem;
  display: inline-block;
  height: .375rem;
  margin-right: 5vw;
  width: 20vw;
  
  ${mq.mq600`
    margin-right: 2.5rem;
    width: 10rem;
  `}
`;
