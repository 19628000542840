import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography/Typography';
import { Link } from 'gatsby';
import CookieConsent from 'react-cookie-consent';

import { SocialIcon } from '../components';
import {
  Container,
  FooterContainer,
  FooterWebsite,
  FooterAddress,
  FooterAddressSocial,
  FooterAddressSocialIconsContainer,
  FooterAddressWrapper,
  FooterCopyrightWrapper,
  Paragraph,
} from '../styled';

export class Footer extends PureComponent {
  static propTypes = {
    contactSectionContent: PropTypes.object.isRequired,
  };

  render() {
    const { contactSectionContent } = this.props;
    const {
      contact_address_heading,
      contact_address_email,
      contact_address_website,
      contact_address_street,
      contact_address_city,
      contact_social_heading,
      contact_copyright_informations,
    } = contactSectionContent;
    return (
      <div style={{ background: 'blue', marginTop: '3rem' }}>
        <Container style={{ paddingTop: '2rem', paddingBottom: '1rem' }}>
          <FooterContainer>
            <FooterAddressWrapper>
              <FooterAddress>
                <a href={`mailto:${contact_address_email}`}>{contact_address_email}</a>
                <span>
                  {contact_address_street}
                </span>
                <span>
                  {contact_address_city}
                </span>
              </FooterAddress>
              <FooterWebsite>
                <a style={{display:'block'}} href="https://www.instagram.com/linkvisuals/">Instagram</a>
                <a style={{display:'block'}} href="https://www.facebook.com/linkvisuals/">Facebook</a>
                <a style={{display:'block'}} href="https://vimeo.com/linkvisuals">Vimeo</a>
              </FooterWebsite>
            </FooterAddressWrapper>
            <FooterAddressSocial>
              <FooterAddressSocialIconsContainer>
              </FooterAddressSocialIconsContainer>
            </FooterAddressSocial>
          </FooterContainer>
          <FooterCopyrightWrapper style={{textAlign:'right'}}>
            <Paragraph marginBottom="0">{contact_copyright_informations} <Link to="/cookie-policy">Cookie policy</Link></Paragraph>
          </FooterCopyrightWrapper>
        </Container>
          <CookieConsent
              location="bottom"
              buttonText="Rozumiem"
              containerClasses='container-cookie'
              buttonClasses='container-cookie-btn'

              contentClasses='container-cookie-content'
              contentStyle={{margin: '.1rem 0 0 0', background:'#000000'}}
              declineButtonText="Decline"
              style={{background:'#000000', border:''}}
              buttonStyle={{ color: '#ffffff', background:'linear-gradient(90deg,#0043ff -90%,#00071a 80%)', fontSize: '14px', border:'1px solid #0043FF', margin: '0' }}
              cookieName="gatsby-gdpr-google-analytics">
              Ta strona przechowuje i ma dostęp do cookies w celu prawidłowego funkcjonowania, analiz i&nbsp;statystyk strony. Dalsze korzystanie ze strony, bez zmiany ustawień w&nbsp;Twojej przeglądarce
              internetowej, oznacza, że zgadzasz się na ich użycie. Więcej informacji znajdziesz w <Link style={{color: '#ffffff', textDecoration:'underline'}} to='/cookie-policy'>Polityce prywatności</Link>.
          </CookieConsent>
      </div>
    );
  }
}
