import styled from 'styled-components';
import { hexToRgba } from '../utils/helpers';
import { COLORS } from '../utils/constants';

export const ImageOverlayedBlend = styled.div`
  background: ${props => (props.gray ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,0.6)')};
  height: 100%;
  left: 0;
  opacity: 1;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  transition: background-color 0.25s ease-in-out;
  
  display:flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    background-color: rgba(0,0,0,0);
  }
  
  h4 {
    text-align:center;
    text-decoration: underline;
    font-size: 30px;
  }
  
`;
