import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography/Typography';

import { mq } from '../utils/helpers';
import { ImageOverlayedBlend } from '../styled';

export class ImageOverlayed extends PureComponent {
  static propTypes = {
    imageName: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    href: PropTypes.string.isRequired,
  };

  render() {
    const {
      imageName, data, children, href, gray
    } = this.props;

    console.log(data);

    return (
      <ImageOverlayedContainer mobileMarginLeft={this.props.mobileMarginLeft} mobileMarginRight={this.props.mobileMarginRight}>
        <a href={href} >
          <Image fluid={data[`${imageName}`].childImageSharp.fluid} />
          <ImageOverlayedBlend gray={gray} className="text-center">
            <Typography variant="h4" style={{fontWeight:'600'}}>
              {children}
            </Typography>
          </ImageOverlayedBlend>
        </a>
      </ImageOverlayedContainer>
    );
  }
}

const ImageOverlayedContainer = styled.div`
  flex-grow: 1;
  overflow: hidden;
  position: relative;
  margin: 1.5rem;
  
  ${mq.mq600`
    height: 10rem;
    margin: .5rem ${props=>props.mobileMarginRight}rem 1.5rem ${props=>props.mobileMarginLeft}rem;
    
    h4 {
      font-size: 18px;
    }
    
    
    .gatsby-image-wrapper {
      height: 200%;
     
    }
    
  `}
  
`;
