import React, { PureComponent } from 'react';
import { graphql, Link } from 'gatsby';

import {
  ImageOverlayed,
  ImageUndertexted
} from '../components';

import {
  Container,
  HomeTextContainer, SectionContainer,
} from '../styled';
import PropTypes from 'prop-types';

export class HomeText extends PureComponent {

  static propTypes = {
    data: PropTypes.object.isRequired,
    offerSectionContent: PropTypes.object.isRequired,
    needMoreContent: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
  };

  render() {
    const {
      data,
      offerSectionContent,
      needMoreContent,
      children,
      marginTop
    } = this.props;

    return (
      <React.Fragment>
        <HomeTextContainer marginTop={marginTop}>
          <Container>
            <p style={{fontSize: this.props.size==null?'20px': this.props.size}}>
              {children}
            </p>
          </Container>
        </HomeTextContainer>
      </React.Fragment>
    );
  }
}
