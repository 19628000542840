import styled from 'styled-components';
import Carousel from 'nuka-carousel';

export const StyledCarousel = styled(Carousel)` //@TODO: moved to higher container because on mobile http problem applying styles
 //&& {
 //   .slider-control-centerleft,
 //   .slider-control-centerright {
 //     display: none;
 //   }
 //   
 //   .slider-control-bottomcenter {
 //     li button {
 //       color: white !important;
 //     }
 //   }
 //}
`;
