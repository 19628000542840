import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import {
  GuideArticleContainer,
  GuideHeading,
  GuideParagraphContainer,
  Paragraph,
} from '../styled';

export class GuideArticle extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    backgroundTheme: PropTypes.string.isRequired,
    headingContent: PropTypes.string.isRequired,
  };

  render() {
    const {
      backgroundTheme,
      headingContent,
      children,
    } = this.props;

    return (
      <GuideArticleContainer backgroundTheme={backgroundTheme}>
        <GuideHeading variant="h1" content={headingContent} backgroundTheme={backgroundTheme} />
        <GuideParagraphContainer content={headingContent}>
          <Paragraph size="4">
            {children}
          </Paragraph>
        </GuideParagraphContainer>
      </GuideArticleContainer>
    );
  }
}
