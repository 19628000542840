import React from 'react';
import styled from 'styled-components';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';

const KeyboardArrowDownContainer = styled.div`
  animation: slideTopBottom 1s alternate infinite;
  bottom: 1rem;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  
  svg {
    fill: ${props => props.theme.colors.red};
  }
`;

export const SlideDownIcon = () => (
  <KeyboardArrowDownContainer>
    <KeyboardArrowDown />
  </KeyboardArrowDownContainer>
);
