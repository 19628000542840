import styled from 'styled-components';

export const SideNavFooter = styled.div`
  div {
  font-size: .625rem;
  opacity: .6;
  }
  
  p {
    font-size: 1rem;
    opacity:1;
    
    span {
      display:block;
    }
    
  }
  
  p a{
    text-decoration:none;
  }
  
  a + a {
    margin-left: 1rem;
  }
  
  a {
    color: inherit;
    text-decoration: underline;
  }
  
  div + div {
    margin-top: 1.5rem;
  }
`;
