import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Typography from '@material-ui/core/Typography';

import { GuideArticle } from '../components';
import {
  CenterContainer,
  Container,
  GuideContainer,
  Paragraph,
  PartnerIconsContainer,
  StyledButton,
} from '../styled';

import { COLORS } from '../utils/constants';
import {
  LogoAxn,
  LogoShowmax,
  LogoDhl,
  LogoHertz,
  LogoLoreal,
  LogoOrange,
} from '../svg';

export class GuideSection extends PureComponent {
  static propTypes = {
    guideSectionContent: PropTypes.object.isRequired,
  };

  render() {
    const {
      blue,
      purple,
      red,
      violet,
    } = COLORS;
    const { guideSectionContent } = this.props;
    const {
      guide_button,
      guide_heading,
      guide_icons_footer,
      guide_article_1,
      guide_article_2,
      guide_article_3,
      guide_article_4,
    } = guideSectionContent;

    return (
      <React.Fragment>
        <StyledContainer>
          <Typography variant="h2" gutterBottom>
            {guide_heading}
          </Typography>
          <GuideContainer>
            <GuideArticle backgroundTheme={red} headingContent="1">
              {guide_article_1}
            </GuideArticle>
            <GuideArticle backgroundTheme={purple} headingContent="2">
              {guide_article_2}
            </GuideArticle>
            <GuideArticle backgroundTheme={violet} headingContent="3">
              {guide_article_3}
            </GuideArticle>
            <GuideArticle backgroundTheme={blue} headingContent="4">
              {guide_article_4}
            </GuideArticle>
          </GuideContainer>
          <CenterContainer height="7rem">
            <Link to="/form/">
              <StyledButton variant="contained" spaceTop={1}>
                {guide_button}
              </StyledButton>
            </Link>
          </CenterContainer>
          <PartnerIconsContainer>
            <LogoAxn fill={red} style={{ height: '2.1rem' }} />
            <LogoShowmax fill={red} style={{ height: '1rem' }} />
            <LogoDhl fill={red} style={{ height: '.9rem' }} />
            <LogoHertz fill={red} style={{ height: '1.6rem' }} />
            <LogoLoreal fill={red} style={{ height: '1.3rem' }} />
            <LogoOrange style={{ height: '3.6rem' }} />
          </PartnerIconsContainer>
          <CenterContainer>
            <Paragraph opacity=".4">
              {guide_icons_footer}
            </Paragraph>
          </CenterContainer>
        </StyledContainer>
      </React.Fragment>
    );
  }
}

// @TODO move to styled dir
const StyledContainer = styled(Container)`
  padding-bottom: 3.75rem;
  padding-top: 3.75rem;
`;
