import styled from 'styled-components';

import { mq } from '../utils/helpers';

export const PartnerIconsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 4.25rem 2.5rem 4rem;
  
  svg {
    margin-top: 2rem;
  
    + svg {
      margin-left: 2rem;
      
      ${mq.mq480`
        margin-left: 0;
      `}
    }
      
    ${mq.mq960`
      margin: 2rem;
    `}

    ${mq.mq480`
      margin: 2rem 0;
    `}
  }
  
  ${mq.mq960`
    justify-content: center;
  `}
  
  ${mq.mq480`
    justify-content: space-around;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    
  `}
`;
