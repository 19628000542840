import styled from 'styled-components';

import { mq } from '../utils/helpers';

export const GuideArticleContainer = styled.div`
  background-color: ${props => props.backgroundTheme};
  max-width: 48%;
  position: relative;
  margin-top: 4rem;
  margin-bottom: 1rem;
  line-height: 1.6rem;

  ${mq.mq1140`
    max-width: none;
    width: 30rem;
  `}
  
  ${mq.mq480`
    max-width: 20rem;
  `}
`;
GuideArticleContainer.defaultProps = {
  backgroundTheme: 'transparent',
};
