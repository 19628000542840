import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography/Typography';

import {
  ImageOverlayed,
  SectionLine,
} from '../components';
import {
  Container,
  GradientBackground,
  OfferSectionArticles,
  SectionContainer,
  Paragraph,
} from '../styled';

export class OfferSection extends PureComponent {
  static propTypes = {
    data: PropTypes.object.isRequired,
    offerSectionContent: PropTypes.object.isRequired,
    needMoreContent: PropTypes.object.isRequired,
  };

  render() {
    const {
      data,
      offerSectionContent,
      needMoreContent,
    } = this.props;
    const {
      offer_heading,
      offer_text_1,
      offer_text_2,
      offer_text_3,
      offer_text_4,
      offer_text_5,
    } = offerSectionContent;
    const {
      needmore_heading,
      needmore_image_text_1,
      needmore_image_text_2,
      needmore_image_text_3,
    } = needMoreContent;

    return (
      <GradientBackground angle="160" style={{ overflow: 'hidden' }}>
        <Container style={{ paddingBottom: '3.125rem', paddingTop: '3.125rem' }}>
          <Typography variant="h2" align="center">
            {offer_heading}
          </Typography>
          <OfferSectionArticles>
            <Paragraph size="5">
              {offer_text_1}
            </Paragraph>
            <SectionLine />
            <Paragraph size="5">
              {offer_text_2}
            </Paragraph>
            <SectionLine reverse />
            <Paragraph size="5">
              {offer_text_3}
            </Paragraph>
            <SectionLine offsetHalf />
            <Paragraph size="5">
              {offer_text_4}
            </Paragraph>
            <SectionLine offsetHalf reverse />
            <Paragraph size="5">
              {offer_text_5}
            </Paragraph>
          </OfferSectionArticles>
          <Typography variant="h2">
            {needmore_heading}
          </Typography>
          <SectionContainer>
            <ImageOverlayed data={data} href="http://www.linkvisuals.pl" imageName="image_adventureman169">
              {needmore_image_text_1}
            </ImageOverlayed>
            <ImageOverlayed data={data} href="http://www.linkvisuals.pl" imageName="image_2_sm169">
              {needmore_image_text_2}
            </ImageOverlayed>
            <ImageOverlayed data={data} href="http://www.linkvisuals.pl" imageName="image_3_sm169">
              {needmore_image_text_3}
            </ImageOverlayed>
          </SectionContainer>
        </Container>
      </GradientBackground>
    );
  }
}
