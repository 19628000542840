import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';
import styled from 'styled-components';

export class ComparisionSlider extends PureComponent {
  static propTypes = {
    data: PropTypes.object.isRequired,
    activeImg: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.imageContainerRef = React.createRef();
    this.imageOverlayRef = React.createRef();
    this.imageSliderRef = React.createRef();

    this.state = {
      loaded: false,
      loadedImages: [],
      activeImage: '',
      imageContainerHeight: 0,
      imageContainerWidth: 0,
      sliderActive: false,
      clicked: false,
    };
  }

  componentDidMount() {
    const { loaded } = this.state;
    const { activeImg } = this.props;
    if (!loaded) {
      this.setState({ loaded: true, activeImage: activeImg });
      this.calculateImageContainerWidth(this.initComparisons);
    }
    window.addEventListener('resize', this.calculateImageContainerWidth, false);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.calculateImageContainerWidth);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.activeImg !== state.activeImage) {
      if (state.loadedImages.includes(props.activeImg)) {
        return {
          activeImage: props.activeImg,
        };
      }
      return {
        activeImage: props.activeImg,
        loadedImages: [props.activeImg, ...state.loadedImages],
      };
    }
    return null;
  }

  getCursorPos(event) {
    const imageOverlay = this.imageOverlayRef.current;
    const e = event || window.event;
    /* get the x positions of the image: */
    const a = imageOverlay.getBoundingClientRect();
    /* calculate the cursor's x coordinate, relative to the image: */
    let x = e.pageX - a.left;
    /* consider any page scrolling: */
    x -= window.pageXOffset;
    return x;
  }

  setSlide(x) {
    const imageOverlay = this.imageOverlayRef.current;
    const slider = this.imageSliderRef.current;

    /* resize the image: */
    imageOverlay.style.width = `${x}px`;

    /* position the slider: */
    slider.style.left = `${imageOverlay.offsetWidth - (slider.offsetWidth / 2)}px`;
  }

  slideMove(e) {
    const {
      clicked,
      imageContainerWidth,
    } = this.state;

    e.preventDefault();
    let pos;

    if (clicked) {
      /* get the cursor's x position: */
      pos = this.getCursorPos(e);

      /* prevent the slider from being positioned outside the image: */
      if (pos < 0) {
        pos = 0;
      } else if (pos > imageContainerWidth) {
        pos = imageContainerWidth;
      }

      /* execute a function that will resize the overlay image according to the cursor: */
      this.setSlide(pos);
    }
    return false;
  }

  slideTouchMove(e) {
    const imageOverlay = this.imageOverlayRef.current;
    const slider = this.imageSliderRef.current;
    const cursorPositionX = e.nativeEvent.changedTouches[0].clientX;
    e.preventDefault();

    /* resize the image: */
    imageOverlay.style.width = `${cursorPositionX}px`;

    /* position the slider: */
    slider.style.left = `${cursorPositionX - (slider.offsetWidth / 2)}px`;
  }

  slideReady(e) {
    e.preventDefault();
    this.setState({ clicked: true });

    window.addEventListener('mousemove', this.slideMove.bind(this));
  }

  initComparisons() {
    const {
      imageContainerHeight,
      imageContainerWidth,
    } = this.state;
    const imageOverlay = this.imageOverlayRef.current;
    const slider = this.imageSliderRef.current;

    imageOverlay.style.width = `${imageContainerWidth / 3}px`;

    /* position the slider in the middle: */
    slider.style.top = `${(imageContainerHeight / 2) - (slider.offsetHeight / 2)}px`;
    slider.style.left = `${(imageContainerWidth / 3) - (slider.offsetWidth / 2)}px`;

    /* execute a function when the mouse button is pressed: */
    slider.addEventListener('mousedown', this.slideReady.bind(this));

    /* and another function when the mouse button is released: */
    window.addEventListener('mouseup', this.slideFinish.bind(this));

    slider.addEventListener('touchstart', e => e.preventDefault(), false);
    slider.addEventListener('touchmove', e => e.preventDefault(), false);
  }

  calculateImageContainerWidth(callback) {
  // @TODO fix me at resize!
    if (this.imageContainerRef) {
      const imageContainer = this.imageContainerRef.current;
      if (imageContainer) {
        this.setState({
          imageContainerHeight: imageContainer.offsetHeight,
          imageContainerWidth: imageContainer.offsetWidth,
          sliderActive: true,
        }, typeof callback === 'function' ? callback : undefined);
      }
    }
  }

  slideFinish() {
    this.setState({ clicked: false });
  }

  renderImage(image, order) {
    const {
      activeImage,
      imageContainerWidth,
    } = this.state;
    const { data } = this.props;
    const display = image === activeImage ? 'block' : 'none';
    return (
      <div className="comparision-img-container" style={{ display, width: imageContainerWidth }} key={image}>
        <Image fluid={data[`image_correction_${image}_lg_${order}`].childImageSharp.fluid} style={{ width: '100%' }} />
      </div>
    );
  }

  render() {
    const {
      loadedImages,
      sliderActive,
    } = this.state;

    return (
      <div>
        <ImageComparision>
          <div className="img-comp-container" style={{ paddingBottom: '42%' }} ref={this.imageContainerRef}>
            <div className="img-comp-img">
              {loadedImages.map(image => this.renderImage(image, 'before'))}
            </div>
            {
              sliderActive && (
                <div
                  className="img-comp-slider"
                  ref={this.imageSliderRef}
                  onTouchMove={this.slideTouchMove.bind(this)}
                  onTouchStart={e => e.preventDefault()}
                />
              )
            }
            <div className="img-comp-img img-comp-overlay" ref={this.imageOverlayRef}>
              {loadedImages.map(image => this.renderImage(image, 'after'))}
            </div>
          </div>
        </ImageComparision>
      </div>
    );
  }
}

const ImageComparision = styled.div`
  .img-comp-container {
    position: relative;
    //height: 200px; /*should be the same height as the images*/
    height: 100%;
    width: 100%;
  }
  
  .gatsby-image-wrapper {
    div {
      touch-action: pan-y;
    }
  }
  
  .img-comp-img {
    position: absolute;
    width: 100%;
    height: auto;
    overflow: hidden;
  }
  
  .img-comp-img img {
    display: block;
    vertical-align: middle;
  }
  
  .img-comp-slider {
    position: absolute;
    z-index: 9;
    cursor: ew-resize;
    /*set the appearance of the slider:*/
    width: 40px;
    height: 110%;
    touch-action: pan-y;
    
    &::before {
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IldhcnN0d2FfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj48c3R5bGUgdHlwZT0idGV4dC9jc3MiPi5zdDB7ZmlsbDojRkYwMTQ2O30uc3Qxe2ZpbGw6I0ZGRkZGRjt9PC9zdHlsZT48Zz48Y2lyY2xlIGNsYXNzPSJzdDAiIGN4PSIxMiIgY3k9IjEyIiByPSIxMiIvPjxwb2x5Z29uIGNsYXNzPSJzdDEiIHBvaW50cz0iMjAsMTIgMTUsNyAxNSwxNyAiLz48cG9seWdvbiBjbGFzcz0ic3QxIiBwb2ludHM9IjQsMTIgOSwxNyA5LDcgIi8+PC9nPjwvc3ZnPg==);
      content: '';
      height: 32px;
      width: 32px;
      position: absolute;
      left: 4px;
      top: 66%;
      opacity: 0;
      transition: opacity .25s ease;
    }
    
    &:hover {
      &::before {
        opacity: 1;
      }
    }
    
    &::after {
      content: '';
      height: 100%;
      opacity: .7;
      width: 4px;
      background-color: #ff0146;
      position: absolute;
      left: calc(50% - 2px);
    }
  }
`;
