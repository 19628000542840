import styled from 'styled-components';

import { mq } from '../utils/helpers';

export const FooterCopyrightWrapper = styled.div`
  font-family: 'Galano Grotesque', Helvetica, Arial, sans-serif;
  font-size: .625rem;
  margin-top: 2.25rem;
  opacity: .6;

  a {
    color: inherit;
    text-decoration: underline;
    
    + a {
      margin-left: 1rem;
    }
  }
  
  ${mq.mq960`
    margin: 3rem 0 2rem;
  `}
`;
