import styled from 'styled-components';

import { mq } from '../utils/helpers';

export const VisualEffectCtaContainer = styled.div`
  align-items: center;
  display: flex;
  height: 6.25rem;
  width: 100%;
  
  > h2 {
    width: calc(50% - 5.8125rem);
    
    ${mq.mq960`
      margin: 3rem 0 2rem;
      width: 16rem;
    `}
  }
  
  ${mq.mq960`
    align-items: flex-start;
    flex-direction: column;
    height: auto;
    margin-bottom: 3rem;
  `}
`;
