import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { SocialIconLink } from '../styled';

import {
  IconVimeo,
  IconFacebook,
  IconInstagram,
  IconYoutube,
} from '../svg';

export class SocialIcon extends PureComponent {
  static propTypes = {
    icon: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.renderIcon = this.renderIcon.bind(this);
  }

  renderIcon(icon) {
    let href;
    let children;

    switch (icon) {
      case 'vimeo':
        href = 'https://vimeo.com/linkvisuals';
        children = <li>Vimeo</li>;
        break;
      case 'facebook':
        href = 'https://www.facebook.com/linkvisuals/';
        children = <li>Facebook</li>;
        break;
      case 'instagram':
        href = 'https://www.instagram.com/linkvisuals/';
        children = <li>Instagram</li>;
        break;
      case 'youtube':
        href = 'https://www.youtube.com/channel/UC-nDiNlAgyX_nC3TrBVCszA';
        children = <li>Youtube</li>;
        break;
      case 'behance':
        href = 'https://www.behance.net/linkvisuals';
        children = <li>Behance</li>;
        break;
      default:
        return null;
    }

    return (
      <SocialIconLink
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </SocialIconLink>
    );
  }

  render() {
    const { icon } = this.props;
    return (
      <React.Fragment>
        {this.renderIcon(icon)}
      </React.Fragment>
    );
  }
}
