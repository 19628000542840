import styled from 'styled-components';

export const SideNavSocialIconsContainer = styled.div`
  align-items: right;
  display: flex;
  margin-top: 2rem;
  flex-direction: column;
  font-size: 1.3rem;
  
  a + a {
    margin-left: 1.33rem;
  }
  
  a {
    min-width: 2rem;
    text-align: center;
  }
  
  svg {
    cursor: pointer;
  }
`;
