import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button/Button';
import { mq } from '../utils/helpers';

import { hexToRgba } from '../utils/helpers';
import {
  COLORS,
  DIMENSIONS,
} from '../utils/constants';

export const StyledButton = styled(({ spaceTop, ...rest }) => <Button {...rest} />)`
  && {
    overflow: hidden;
    position: relative;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    background: rgb(0,67,255);
    background: linear-gradient(90deg, #0043ff -90%, #00071a 80%);
    border: 1px solid #0043FF;
    font-size: 24px;
    margin: 1.5rem;
    min-height: 54px;
    
    flex: 1 0 20%;
    
    ${mq.mq960`
      flex: 2 0 30%;
      margin: .5rem 1.5rem;
    `}
    
        
    ${mq.mq480`

      flex: 1 0 50%;
      margin: .5rem 1.5rem;
    `}
    
    span {
      z-index: 2;
    }

    &::before {
    -webkit-backface-visibility: hidden;
      background: linear-gradient(90deg, #00071a 0%, #0043ff 100%);
      border-radius: ${DIMENSIONS.buttonBorderRadius};
      content: '';
      height: 100%;
      left: 0;
      opacity: 1;
      position: absolute;
      top: 0;
      transition: opacity .3s ease, left .3s ease;
      width: 200%;
      z-index: 1;
    }
    
    &:hover::before {
      left: -100%;
      opacity: 1;
    }
  }
  
`;
