import React, { PureComponent } from 'react';
import { graphql, Link } from 'gatsby';

import {
  ImageOverlayed,
  ImageUndertexted
} from '../components';

import {
  Container,
  SectionContainer
} from '../styled';


import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mq } from '../utils/helpers';

export class Boxes extends PureComponent {

  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  render() {
    const {
      data, page
    } = this.props;

    console.log('PAGE', data);

    let maxPages = data.length;

    if (page !== undefined && page !== null) {
      maxPages = page;
    }

    return (
      <React.Fragment>
        <BoxesContainer>
          <Container>
            {data.slice(0, maxPages).map((boxes, boxindex) => {
              return <SectionContainer>
                {boxes.row.map((row ,rowindex) => {
                  return (<ImageUndertexted image={row.box.image_url} hover={row.box.hover_url} href={row.box.destination}>
                    {row.box.title}
                  </ImageUndertexted>)
                })}
              </SectionContainer>
            })}
          </Container>
        </BoxesContainer>
      </React.Fragment>
    );
  }
}

const BoxesContainer = styled.div`
  flex-grow: 1;
  position: relative;
`;

