import styled from 'styled-components';

import { mq } from '../utils/helpers';

export const HomeTextContainer = styled.div`
  align-items: center;
  display: flex;
  max-width: 100%;
  margin-top: ${props => props.marginTop+'rem'};
  
`;
