import styled from 'styled-components';
import { mq } from '../utils/helpers';

export const StyledFilterArticlesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 3.75rem 0 1.875rem;
  position: relative;

  .slider-control-centerleft.slider-control-centerleft, // moved from carousel container
  .slider-control-centerright.slider-control-centerright {
    display: none;
  }
  
  .slider-control-bottomcenter.slider-control-bottomcenter {
    li button {
      color: white !important;
    }
  }

  
  ${mq.mq600`
    padding: 1.875rem 0 0;
  `}
`;
