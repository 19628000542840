import styled from 'styled-components';
import { mq } from '../utils/helpers';

export const InteractiveFilterContainer = styled.div`
  padding-top: 2rem;

  img {
    pointer-events: none;
    user-select: none;
  }

  ${mq.mq960`
    margin-left: -2rem;
    margin-right: -2rem;
  `}
`;
