import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Mute, Unmute } from '../svg';
import { mq } from '../utils/helpers';

export class MuteButton extends PureComponent {
  render() {

    let state = this.props.mute;

    console.log(this.props.clickAction);

    return (
      <MuteButtonContainer home={this.props.home}>
        {state?<Unmute onClick={()=> this.props.clickAction() }/>:<Mute onClick={()=> this.props.clickAction() }/>}
      </MuteButtonContainer>
    );
  }
}


const MuteButtonContainer = styled.div`
  display: flex;
  position: absolute;
  bottom:0;
  right:${props=>props.home?'0':'1'}rem;
  z-index:3;
  text-align: right;
  max-height:100px;
  width: ${props=>props.home?'80':'100'}px;
  opacity: 0.7;
 
  svg {
    margin: 1rem;
    position: absolute;
    bottom:0;
    right:${props=>props.home?'0':'1.5'}rem;rem;
  }
  
  ${mq.mq960`
      width: ${props=>props.home?'40':'40'}px;
      bottom:${props=>props.home?'.5':'0'}rem;
      
      svg {
        margin: .3rem .3rem .3rem auto;
        right:${props=>props.home?'0':'1'}rem;
      }
  `}
  
  &:hover {
    opacity:1;
  }
  
`;
