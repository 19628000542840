import styled from 'styled-components';

import { mq } from '../utils/helpers';

export const FooterAddressSocial = styled.div`
  display: flex;
  flex-grow: 3;
  justify-content: flex-end;

  ${mq.mq960`
    flex-direction: column;
    justify-content: flex-start;
  `}
`;
