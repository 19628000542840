import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';

import { SingleFilterArticle } from './SingleFilterArticle';
import {
  StyledCarousel,
  StyledFilterArticlesContainer,
} from '../styled';

export class FilterArticles extends PureComponent {
  static propTypes = {
    visualEffectsArticles: PropTypes.object.isRequired,
  };

  render() {
    const { visualEffectsArticles } = this.props;
    const {
      visualeffect_article_1_heading,
      visualeffect_article_1_content,
      visualeffect_article_2_heading,
      visualeffect_article_2_content,
      visualeffect_article_3_heading,
      visualeffect_article_3_content,
    } = visualEffectsArticles;
    return (
      <StyledFilterArticlesContainer>
        <MediaQuery minDeviceWidth={960} values={{ deviceWidth: 960 }}>
          {(matches) => {
            if (matches) {
              return (
                <React.Fragment>
                  <SingleFilterArticle
                    article_heading={visualeffect_article_1_heading}
                    article_content={visualeffect_article_1_content}
                  />
                  <SingleFilterArticle
                    article_heading={visualeffect_article_2_heading}
                    article_content={visualeffect_article_2_content}
                  />
                  <SingleFilterArticle
                    article_heading={visualeffect_article_3_heading}
                    article_content={visualeffect_article_3_content}
                  />
                </React.Fragment>
              );
            }
            return (
              <StyledCarousel
                autoplay
                autoplayInterval={6000}
                cellSpacing={60}
                framePadding="0 0 60px"
                wrapAround
              >
                <SingleFilterArticle
                  article_heading={visualeffect_article_1_heading}
                  article_content={visualeffect_article_1_content}
                />
                <SingleFilterArticle
                  article_heading={visualeffect_article_2_heading}
                  article_content={visualeffect_article_2_content}
                />
                <SingleFilterArticle
                  article_heading={visualeffect_article_3_heading}
                  article_content={visualeffect_article_3_content}
                />
              </StyledCarousel>
            );
          }}
        </MediaQuery>
      </StyledFilterArticlesContainer>
    );
  }
}
