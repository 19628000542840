import styled from 'styled-components';

export const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${props => props.height};
`;

CenterContainer.defaultProps = {
  height: 'auto',
};
