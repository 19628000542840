/* eslint-disable no-bitwise */
import { css } from 'styled-components';

import { MQ_SIZES } from './constants';

export const hexToRgba = (hex, alpha) => {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join('')}`;
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')}, ${alpha || 1})`;
  }
  throw new Error('Bad Hex');
};

export const mq = Object.keys(MQ_SIZES).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${MQ_SIZES[label] / 16}rem) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});
