import React, { Component } from 'react';
import { graphql, Link } from 'gatsby';

import {
  ImageOverlayed,
  ImageUndertexted
} from '../components';

import {
  Container,
  HomeTextContainer,
} from '../styled';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mq } from '../utils/helpers';

export class FlatBoxes extends Component {

  static propTypes = {
    data: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {
      data, movies, digital, design
    } = this.props;

    return (
      <React.Fragment>
        <HomeTextContainer>
          <Container>
            <BoxesContainer>
              <ImageOverlayed mobileMarginLeft='1.5' mobileMarginRight='.2' gray={movies} data={data} href="/portfolio/filmy" imageName="filmy">
              Видео
              </ImageOverlayed>
              <ImageOverlayed mobileMarginLeft='.2' mobileMarginRight='.2' gray={digital} data={data} href="/portfolio/digital" imageName="digital">
                Digital
              </ImageOverlayed>
              <ImageOverlayed mobileMarginLeft='.2' mobileMarginRight='1.5' gray={design} data={data} href="/portfolio/design" imageName="design">
              Дизайн
              </ImageOverlayed>
            </BoxesContainer>
          </Container>
        </HomeTextContainer>
      </React.Fragment>
    );
  }
}

export const BoxesContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 6rem -1.5rem 2rem -1.5rem;
  
  ${mq.mq600`
    margin: 6rem -1.5rem .5rem -1.5rem;
  `}

`;

