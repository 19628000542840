import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Fab from '@material-ui/core/Fab';
import { Link } from 'gatsby';

import { IconChat } from '../svg';

const ChatContainer = styled.div`
  bottom: 2rem;
// @TODO chat should stick to right inner main container
  right: calc((100vw - ${props => props.theme.dimensions.containerMaxWidth} - 12px) / 2);
  position: fixed;
  z-index: ${props => props.theme.zIndex.chatButton};
`;

export class Chat extends PureComponent {
  render() {
    return (
      <ChatContainer>
        <Link to="/form">
          <Fab color="primary" aria-label="Get a quote">
            <IconChat style={{ height: 24, marginTop: 3 }} />
          </Fab>
        </Link>
      </ChatContainer>
    );
  }
}
