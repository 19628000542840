import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography/Typography';
import Modal from '@material-ui/core/Modal';

import {
  Container,
  GradientBackground,
  Paragraph,
  StyledButton,
  VisualEffectCtaContainer,
} from '../styled';
import {
  InteractiveFilter,
  FilterArticles,
} from '../components';

export class VisualEffectSection extends PureComponent {
  static propTypes = {
    data: PropTypes.object.isRequired,
    visualeffect_button: PropTypes.string,
    visualeffect_heading: PropTypes.string,
    visualeffect_subheading: PropTypes.string,
    visualEffectsArticles: PropTypes.object.isRequired,
  };

  static defaultProps = {
    visualeffect_button: '',
    visualeffect_heading: '',
    visualeffect_subheading: '',
  };

  state = {
    videoModalOpen: false,
  };

  handleOpenVideoModal = () => {
    this.setState({ videoModalOpen: true });
  };

  handleCloseVideoModal = () => {
    this.setState({ videoModalOpen: false });
  };

  render() {
    const { videoModalOpen } = this.state;
    const {
      data,
      visualeffect_button,
      visualeffect_heading,
      visualeffect_subheading,
      visualEffectsArticles,
    } = this.props;

    return (
      <GradientBackground angle="340">
        <Container>
          <VisualEffectCtaContainer>
            <Typography variant="h2">
              {visualeffect_heading}
            </Typography>
            <StyledButton variant="outlined" onClick={this.handleOpenVideoModal}>
              {visualeffect_button}
            </StyledButton>
          </VisualEffectCtaContainer>
          <div>
            <Paragraph size="3" opacity=".6" style={{ marginBottom: 0 }}>
              {visualeffect_subheading}
            </Paragraph>
            <InteractiveFilter data={data} />
            <FilterArticles visualEffectsArticles={visualEffectsArticles} />
          </div>
        </Container>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={videoModalOpen}
          onClose={this.handleCloseVideoModal}
        >
          <iframe
            src="https://player.vimeo.com/video/301793082"
            frameBorder="0"
            allowFullScreen
            mozallowfullscreen="true"
            webkitallowfullscreen="true"
            title="Link Visuals"
            style={{
              maxHeight: '96vh',
              maxWidth: 1140,
              width: '96vw',
              height: '41.89vw', // movie ratio: 2,387 // original 1289x540
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              position: 'absolute',
              marginBottom: 0,
            }}
          />
        </Modal>
      </GradientBackground>
    );
  }
}
