import { createGlobalStyle } from 'styled-components';

import * as fonts from '../fonts';
import {
  COLORS,
  MQ_SIZES,
} from './constants';
// @TODO move/fix padding-right body and heading mui issue

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Galano Grotesque Regular';
    font-display: fallback;
    src: local('Galano Grotesque Regular'),
         url('${fonts.GalanoGrotesqueRegularWoff2}') format('woff2'),
         url('${fonts.GalanoGrotesqueRegularWoff}') format('woff'),
         url('${fonts.GalanoGrotesqueRegularTtf}') format('truetype'),
         url('${fonts.GalanoGrotesqueRegularEot}?#iefix') format('embedded-opentype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Galano Grotesque Light';
    font-display: fallback;
    src: local('Galano Grotesque Light'),
         url('${fonts.GalanoGrotesqueLightWoff2}') format('woff2'),
         url('${fonts.GalanoGrotesqueLightWoff}') format('woff'),
         url('${fonts.GalanoGrotesqueLightTtf}') format('truetype'),
         url('${fonts.GalanoGrotesqueLightEot}?#iefix') format('embedded-opentype');
    font-weight: 300;
    font-style: normal;
  }
  
  @keyframes slideTopBottom {
    from {
      bottom: 1rem;
    }
    to {
      bottom: 2rem;
    }
  }
  
  @keyframes horizontalMove {
    from {
      left: 50vw;
    }
    to {
      left: 25vw;
    }
  }
  
  html {
      scroll-behavior: smooth;
  }
  
  body {
    background-color: ${COLORS.black};
    line-height: 1.666;
    padding-right: 0 !important;
  }
  

  @media(max-width: ${MQ_SIZES.mq960}px) {
    .text-sm-right {
        text-align: right;
    }
  }
  
  
  img {
    margin-bottom: 0;
  }
  
  .container-cookie {
     display: flex;
  }
  
  .container-cookie div:not(.container-cookie-content) {
    background: #000000;
    position: absolute;
    padding: 1rem;
    margin: 0;
    bottom: 0;
    right: 0;
    height: calc(100% - .1rem);
    
    button {
      margin: auto;
    }
      
    @media(max-width: ${MQ_SIZES.mq600}px) {
        width: 100%;
        text-align: center;
        position: relative;
        padding: 0 0 1rem 0;
    }
      
  }
  
  .container-cookie-content{
  
    padding: .5rem 140px .5rem .5rem;
    width:80%;
    padding-right: 140px;
    
    @media(max-width: ${MQ_SIZES.mq600}px) {
          padding-right: 0;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      z-index: -1;
      margin: -$border; /* !importanté */
      border-radius: inherit; /* !importanté */
      background: linear-gradient(-90deg,rgba(0,67,255,1) 0%,rgba(255,1,70,1) 100%);
      
    }
    
    div {
      margin:0;
    }
    
}

  
  header {
    padding-right: 0 !important;
  }
  
  a {
    text-decoration: none;
  }
  
  .link-button {
  
    &:hover {
      color: #0043FF;
    }
  
  }
  
  .link-contact {
    color: #ffffff;
    &:hover {
      color: #FF0146;
    }
  
  }
  
  @media(max-width: ${MQ_SIZES.mq360}px) {
    html {
      font-size: 85%;
    }
  }
  
  @media(min-width: ${MQ_SIZES.mq1920}px) {
    html {
      font-size: .85vw;
    }
  }
`;
