import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography/Typography';

import { mq } from '../utils/helpers';

export const GuideHeading = styled(({ backgroundTheme, content, ...rest }) => <Typography {...rest} />)`
  background-color: ${props => props.backgroundTheme};
  border-radius: 50%;
  height: 4rem;
  left: 50%;
  position: absolute;
  text-align: center;
  top: 0;
  transform: translate(-50%, -50%);
  width: 4rem;
  
  &:before {
    background-color: rgba(0,0,0, .15);
    border-radius: 50%;
    content: '${props => props.content}';
    height: 100%;
    padding-top: .2rem;
    left: 0;
    opacity: 1;
    position: absolute;
    top: 0;
    width: 100%;

    ${mq.mq600`
      padding-top: .6rem;
    `}
  }

  ${mq.mq600`
    height: 5rem;
    width: 5rem;
  `}
`;
GuideHeading.defaultProps = {
  backgroundTheme: 'transparent',
  content: '',
};
