import styled from 'styled-components';

import { mq } from '../utils/helpers';

export const FooterAddress = styled.div`
  padding-top: .5rem;

  span {
    display: block;
  }
  
  ${mq.mq960`
    margin-top: 1rem;
  `}
`;
