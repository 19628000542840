import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  margin: 0 auto;
  max-width: ${props => props.theme.dimensions.containerMaxWidth};
  padding-left: 2rem;
  padding-right: 2rem;
  text-align: ${props => (props.center ? 'center' : '')};
`;
