import styled from 'styled-components';
import { Container } from '.';

import { mq } from '../utils/helpers';

export const SideNavContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  text-align: right;
  padding-bottom: 4rem;
  padding-top: 2.25rem;
  width: 70%;

  ${mq.mq480`
    padding: 1.5rem 0 2rem
    width: 75%;
  `}
`;
