import styled from 'styled-components';

import { mq } from '../utils/helpers';

export const FooterAddressSocialIconsContainer = styled.div`
  padding-top: .15rem;
  text-align: right;
  width: 16rem;
  
  a {
    min-width: 2rem;
    text-align: center;
    
    + a {
      margin-left: 2rem;
    }
  }
  
  svg {
    cursor: pointer;
  }
  
  ${mq.mq960`
    margin-top: 1.2rem;
    text-align: left;
  `}
`;
