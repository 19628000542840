import React from 'react';
import styled from 'styled-components';
import FormGroup from '@material-ui/core/FormGroup';

import { mq } from '../utils/helpers';

export const StyledFormGroup = styled(({ textArea, withCheckbox, ...rest }) => <FormGroup {...rest} />)`
  justify-content: ${props => (props.withCheckbox ? 'flex-start' : 'space-between')};
  margin-bottom: ${props => (props.withCheckbox ? '.1875rem' : '.9375rem')};
  margin-top: ${props => (props.withCheckbox ? '.1875rem' : '.9375rem')};
  
  > div {
    flex-basis: ${props => (props.textArea ? '100%' : props.theme.dimensions.formElementWidthStandard)};
  }
  
  label {
    margin-left: -.75rem;
    user-select: none;

    + label {
      margin-left: .625rem;
    }
  }

  ${mq.mq960`
    && {
      flex-direction: column;
      max-width: 30rem;
      
      > div + div {
        margin-top: 2rem;
      }
      
      label {
        margin-left: -.75rem;
      }
    }
  `}
`;
