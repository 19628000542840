import React, { PureComponent } from 'react';
import { graphql, Link } from 'gatsby';

import {
  ImageOverlayed,
  ImageUndertexted
} from '../components';

import {
  Container,
  SectionContainer
} from '../styled';


import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mq } from '../utils/helpers';

export class Text extends PureComponent {

  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  render() {
    const {
      client, description, role
    } = this.props;

    return (
      <React.Fragment>
        <BoxesContainer>
          <Container>
            <Text></Text>
          </Container>
        </BoxesContainer>
      </React.Fragment>
    );
  }
}

const BoxesContainer = styled.div`
  flex-grow: 1;
  position: relative;
  margin-top:1.5rem;
`;
