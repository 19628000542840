import styled from 'styled-components';

import { mq } from '../utils/helpers';

export const FooterAddressWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;

  ${mq.mq960`
    flex-direction: column;
  `}
`;
