import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button/Button';

import { hexToRgba } from '../utils/helpers';
import {
  COLORS,
  DIMENSIONS,
} from '../utils/constants';

export const StyledTextButton = styled(({ spaceTop, ...rest }) => {
  return <Button {...rest} />
})`
  && {
    margin-top: ${props => (props.spaceTop && `${props.spaceTop}rem`)};
    overflow: hidden;
    position: relative;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    color: ${props=> (props.active?'#0043FF':'#ffffff')};

    span {
      z-index: 2;
    }

  }
  
`;
