import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import { BrandIconWithText } from '.';
import {
  AppBarHeaderContainer,
  StyledAppBar,
  HeaderUpperline,
  StyledLangButton
} from '../styled';

export class Header extends PureComponent {
  static propTypes = {
    onOpenMenu: PropTypes.func.isRequired,
  };

  render() {
    const { onOpenMenu } = this.props;

    return (
      <React.Fragment>
        <StyledAppBar position="fixed">
          <Toolbar style={{ paddingLeft: 0, paddingRight: 0 }}>
            <HeaderUpperline />
            <AppBarHeaderContainer>
                <Link to="/">
                  <BrandIconWithText />
                </Link>
              <div>

                <IconButton
                  aria-label="Menu"
                  color="inherit"
                  onClick={onOpenMenu}
                  style={{ marginRight: '-.75rem', marginTop: '.5rem' }}
                >
                  <MenuIcon />
                </IconButton>
              </div>
            </AppBarHeaderContainer>
          </Toolbar>
        </StyledAppBar>
      </React.Fragment>
    );
  }
}
