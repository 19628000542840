import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';
import styled from 'styled-components';

export class InteractiveMiniature extends Component {
  static propTypes = {
    active: PropTypes.string.isRequired,
    miniature: PropTypes.string.isRequired,
    onImageClick: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
  };

  render() {
    const {
      active,
      onImageClick,
      miniature,
      data,
    } = this.props;

    return (
      <SingleMiniatureContainer
        onClick={() => onImageClick(miniature)}
        role="button"
        className={miniature === active ? 'active' : ''}
      >
        <Image fluid={data[`image_correction_${miniature}_sm`].childImageSharp.fluid} />
      </SingleMiniatureContainer>
    );
  }
}

const SingleMiniatureContainer = styled.div`
  cursor: pointer;
  flex-grow: 1;
  opacity: .5;
  
  &.active {
    opacity: 1;
    outline: 1px solid ${props => props.theme.colors.red};
    z-index: 1;
  }
`;
