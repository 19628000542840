import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';

const backgroundImage = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "adventureman-mobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export const ImageBackground = () => (
  <StaticQuery
    query={backgroundImage}
    render={data => (
      <ImageBg>
        <MediaQuery minDeviceWidth={1024} device={{ deviceWidth: 1024 }}>
          {(matches) => {
            if (matches) {
              return (
                <React.Fragment>
                  <video id="background-video" loop playsInline autoPlay muted>
                    <source src="http://linkvisuals.pl/media/contact.mp4" type="video/mp4" />
                  </video>
                </React.Fragment>
              );
            }
            return (
              <Img fluid={data.placeholderImage.childImageSharp.fluid} />
            );
          }}
        </MediaQuery>
      </ImageBg>
    )}
  />
);

const ImageBg = styled.div`
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute !important;
  top: 0;
  width: 100%;
  z-index: -1;

  & > video {
    height: 100%;
    min-width: 100%;
    opacity: .65;  
    object-fit: cover !important;
    object-position: 0 0 !important;
    font-family: 'object-fit: cover !important; object-position: 0% 0% !important;'; // needed for IE9+ polyfill
  }
  
  .gatsby-image-wrapper {
    object-fit: cover !important;
    object-position: 0 0 !important;
    font-family: 'object-fit: cover !important; object-position: 0% 0% !important;'; // needed for IE9+ polyfill
    top: 0;
    height: 100%;
    left: 0;
    min-width: 100%;
    position: absolute !important;
    z-index: -1;
  }
`;
