import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import {
  ComparisionSlider,
  InteractiveMiniature,
} from '.';

import {
  InteractiveFilterContainer,
  InteractiveFilterMiniaturesContainer,
} from '../styled';

export class InteractiveFilter extends PureComponent {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      activeImg: '1',
    };

    this.handleChangeMainImage = this.handleChangeMainImage.bind(this);
  }

  handleChangeMainImage(img) {
    this.setState({ activeImg: img });
  }

  render() {
    const { data } = this.props;
    const { activeImg } = this.state;

    return (
      <InteractiveFilterContainer>
        <ComparisionSlider data={data} activeImg={activeImg} />
        <InteractiveFilterMiniaturesContainer>
          <InteractiveMiniature
            data={data}
            miniature="1"
            onImageClick={this.handleChangeMainImage}
            active={`${activeImg}`}
          />
          <InteractiveMiniature
            data={data}
            miniature="2"
            onImageClick={this.handleChangeMainImage}
            active={`${activeImg}`}
          />
          <InteractiveMiniature
            data={data}
            miniature="3"
            onImageClick={this.handleChangeMainImage}
            active={`${activeImg}`}
          />
        </InteractiveFilterMiniaturesContainer>
      </InteractiveFilterContainer>
    );
  }
}
