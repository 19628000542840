import styled from 'styled-components';
import TextField from '@material-ui/core/TextField/index';

export const StyledTextField = styled(TextField)`
  &&& {
    margin-top: 2rem;
    
    fieldset {
      border-width: 1px;
    }
    
    label {
      color: ${props => props.theme.colors.white};
      transform: translate(.8rem, -1.66rem) !important;
      transition: none;
    }
    
    legend {
      width: 0 !important;
    }
  }
`;
