import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';

import {
  Chat,
  Header,
  SideNav,
} from '../components';

import { GlobalStyle } from '../utils/globals';
import { variables } from '../utils/theme';
import withRoot from '../withRoot';

class LayoutComponent extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    renderWithChat: PropTypes.bool,
  };

  static defaultProps = {
    renderWithChat: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      sideNavOpen: false,
    };

    this.toggleSideNav = this.toggleSideNav.bind(this);
  }

  toggleSideNav = open => () => {
    this.setState({
      sideNavOpen: open,
    });
  };

  render() {
    const { sideNavOpen } = this.state;
    const {
      children,
      renderWithChat,
    } = this.props;

    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <React.Fragment>
            <Helmet
              title={data.site.siteMetadata.title}
              meta={[
                { name: 'description', content: 'Link Visuals' },
                { name: 'keywords', content: 'color correction, Link Visuals' },
              ]}
            >
              <html lang="en" />
            </Helmet>
            <ThemeProvider theme={variables}>
              <React.Fragment>
                <GlobalStyle />
                <SideNav open={sideNavOpen} close={this.toggleSideNav(false)} />
                <Header
                  siteTitle={data.site.siteMetadata.title}
                  onOpenMenu={this.toggleSideNav(true)}
                />
                <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
                  {children}
                </div>
              </React.Fragment>
            </ThemeProvider>
          </React.Fragment>
        )}
      />
    );
  }
}

export const Layout = withRoot(LayoutComponent);
