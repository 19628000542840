import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link, StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';
import { SlideDownIcon } from '../components/SlideDownIcon';
import {
  HeroContainer, Container, StyledButton
} from '../styled';

import { MuteButton } from '../components';

const backgroundImage = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  } 
`;

export class HeroImage extends PureComponent {
  static propTypes = {
    heroSectionContent: PropTypes.object.isRequired,
  };

  constructor(props){
      super(props);

      this.state = {
          mute: true
      }

      this.videoRef = React.createRef();
  }

    ChangeMute() {
        console.log(this.videoRef);
        this.videoRef.current.muted = !this.state.mute;
        this.setState({mute: !this.state.mute});
    }

  render() {
    return (
        <React.Fragment>
          <HeroContainer>
            <Container>
                <div style={{  position: 'relative'}}>
              <video ref={this.videoRef} id="background-video" style={{width:'100%', objectFit: 'cover'}} poster='https://linkvisuals.pl/media/home-poster.png' loop playsInline autoPlay muted>
                  <source src="https://linkvisuals.pl/media/herovideo.mp4" type="video/mp4"/>
              </video>
                <MuteButton home={true} mute={this.state.mute} clickAction={()=> this.ChangeMute()}/>
                </div>
            </Container>
          </HeroContainer>
        </React.Fragment>
    );
  }
}
