import React from 'react';
import styled from 'styled-components';

export const Paragraph = styled(({
  marginTop, opacity, size, marginBottom, ...rest
}) => <p {...rest} />)`
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.paragraphSizes[props.size]};
  margin-bottom: ${props => props.marginBottom};
  margin-top: ${props => props.marginTop};
  opacity: ${props => props.opacity};
`;

Paragraph.defaultProps = {
  marginTop: 0,
  opacity: 1,
  size: props => props.theme.paragraphSizes['4'],
};
