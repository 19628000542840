import React from 'react';
import {
  StyledSectionLineWrapper,
  StyledSectionLineContainer,
  StyledSectionLineSingle,
} from '../styled';

export const SectionLine = ({ ...reverse }, { ...offsetHalf }) => (
  <StyledSectionLineWrapper>
    <StyledSectionLineContainer {...reverse} {...offsetHalf}>
      <StyledSectionLineSingle />
      <StyledSectionLineSingle />
      <StyledSectionLineSingle />
      <StyledSectionLineSingle />
      <StyledSectionLineSingle />
      <StyledSectionLineSingle />
    </StyledSectionLineContainer>
  </StyledSectionLineWrapper>
);
