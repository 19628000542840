import styled from 'styled-components';

export const FileDropdownContainer = styled.div`
  max-width: 560px;
  width: ${props => (props.withFile ? 'auto' : '31%')};

  .filepond--panel-root {
    background-color: ${props => props.theme.colors.blue};
    border-radius: 0;
  }

  .filepond--drop-label label {
    color: ${props => props.theme.colors.white};
    font-family: 'Galano Grotesque', Helvetica, Arial, sans-serif;
    font-size: 1rem;
  }
`;
