import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography/Typography';
import ReactResizeDetector from 'react-resize-detector';

import { ImageOverlayedBlend } from '../styled';
import { mq } from '../utils/helpers';

export class ImageUndertexted extends PureComponent {
  static propTypes = {
    imageName: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    href: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      video: false,
      width: 0,
      height: 0
    }
  }

  playVideo = (val) => {
    if (this.state.width>0) {
      this.setState({video: val});
    }
  }

  onResize = (width, height) => {
    if (!this.state.video) {
      this.setState({
        width,
        height
      });
    }
  };

  render() {
    const {
      image, href, children, hover
    } = this.props;

    let videoWidth = this.state.width;
    let videoHeight = this.state.height-26.75;

    let imageObj = <ImageZoomer><Image style={{marginBottom: 0, paddingBottom:0}} fluid={image.childImageSharp.fluid} ></Image></ImageZoomer>;

    console.log(image, hover);
    if (this.props.hover != null) {

      imageObj = <Image style={{marginBottom: 0, paddingBottom:0}} fluid={image.childImageSharp.fluid} ></Image>;

      if (this.state.video) {
          imageObj = <ImageZoomer><Image style={{marginBottom: 0, paddingBottom:0}} fluid={image.childImageSharp.fluid} ></Image><div style={{width:videoWidth + 'px', height: videoHeight + 'px', background: 'transparent', position:'absolute', top:0, left:0}}><video style={{
            display: 'block',
            width: '100%',
            maxWidth: videoWidth + 'px',
            height: videoHeight + 'px',
            margin: 0,
            objectFit: 'cover'
          }} src={hover.publicURL} autoPlay="autoPlay" loop playsInline autoPlay muted></video></div></ImageZoomer>;
        }
    }

    return (
        <ImageOverlayedContainer>
          <ReactResizeDetector handleWidth handleHeight onResize={this.onResize}>
          <a href={href} onMouseEnter={()=>this.playVideo(true)} onMouseLeave={()=>this.playVideo(false)}>
            {imageObj}
          </a>
          </ReactResizeDetector>
          <Undertext>{children}</Undertext>
        </ImageOverlayedContainer>
    );
  }
}

const ImageZoomer = styled.div`
  overflow: hidden;
  position: relative;
  
  width:100%;
  
  .gatsby-image-wrapper img {
    transition: transform .25s ease !important;
  }
  
  &:hover img {
    transform: scale(1.1);
    transition: transform .25s ease;
  }
  
`;

const ImageOverlayedContainer = styled.div`

  flex-column: 1;
  margin:1.5rem;
  width:100%;
  
   ${mq.mq600`
    width:auto;
  `}

`;

const Undertext = styled.div`
  margin-top: 10px;
  font-size: 16px;
  color: #808080;
`;
