import React, { PureComponent, useRef } from 'react';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';
import styled from 'styled-components';
import { MuteButton } from '../components'

import {
  HeroContainer
} from '../styled';

export class Slider extends PureComponent {
  static propTypes = {
    slides: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.videoRef = React.createRef();

    let slidesArray = this.props.slides.map((row ,rowindex) => {
      return row.slide;
    });

    this.state = {
      slides: slidesArray,
      slide: 0,
      mute: true
    }
  }

  Next() {
    let nextSlideIndex = this.state.slide+1;
    if (nextSlideIndex >= this.state.slides.length) {
      nextSlideIndex = 0;
    }
    this.setState({ slide: nextSlideIndex });
  }

  Previous() {
    let nextSlideIndex = this.state.slide-1;
    if (nextSlideIndex < 0) {
      nextSlideIndex = this.state.slides.length-1;
    }
    this.setState({ slide: nextSlideIndex });
  }

  ChangeMute() {
    console.log(this.videoRef);
    this.videoRef.current.muted = !this.state.mute;
    this.setState({mute: !this.state.mute});
  }

  render() {
    const { slides } = this.props;
    let slide = this.state.slides[this.state.slide];

    let mediaObject = slide.image_url!=null?<Image fluid={slide.image_url.childImageSharp.fluid} />: <video ref={this.videoRef} style={{
      display: 'block',
      width: '100%',
      maxHeight: '547.2px',
      margin: 0,
      objectFit: 'cover'
    }} src={slide.video_url.publicURL} autoPlay="autoPlay" loop playsInline autoPlay muted></video>;

    return (
        <React.Fragment>
          <HeroContainer>
            <SliderContainer>
              {mediaObject}
              {this.state.slides.length>1?
              <ButtonsContainer>
                <ButtonContainer>
                  <SliderButton onClick={()=>this.Previous()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22.982" height="42.982" viewBox="0 0 22.982 42.982">
                      <path id="Union_3" data-name="Union 3" d="M7.509,22.774,27.355,2.929,7.509-16.917l1.645-1.645L30.491,2.775l-.154.154.153.153L9.154,24.42Z" transform="translate(30.491 24.42) rotate(180)" fill="#fff"/>
                    </svg>
                  </SliderButton>
                </ButtonContainer>
                <ButtonContainer style={{ justifyContent: 'right' }} >
                  <SliderButton style={{marginLeft:'auto'}} onClick={()=>this.Next()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22.982" height="42.982" viewBox="0 0 22.982 42.982">
                      <path id="Union_2" data-name="Union 2" d="M7.509,22.774,27.355,2.929,7.509-16.917l1.645-1.645L30.491,2.775l-.154.154.153.153L9.154,24.42Z" transform="translate(-7.509 18.563)" fill="#fff"/>
                    </svg>
                  </SliderButton>
                </ButtonContainer>
              </ButtonsContainer>:<div></div>}
              {slide.image_url==null?<MuteButton home={false} mute={this.state.mute} clickAction={()=> this.ChangeMute()}/>:<div></div>}
            </SliderContainer>
          </HeroContainer>
        </React.Fragment>
    );
  }
}

const SliderButton = styled.div`
  overflow: hidden;
  height: 63px;
  width: 62px;
  background-color: #000000;
  border: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background .4s ease;
  cursor: pointer;

  &:hover {
    background-color: #0043FF;
  }

`;

const ButtonsContainer = styled.div`
  flex-grow: 1;
  overflow: hidden;
  position: absolute;
  top:0;
  left:2rem;
  flex: 1;
  width: calc(100% - 4rem);
  max-width: 75.25rem;
  height:100%;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  transition: opacity .5s;
  z-index: 3;
  
  &:hover div {
    visibility:visible; 
    opacity:1;
  }
  
`;

const ButtonContainer = styled.div`
  width:50%;
  display: flex;
  align-items: center;
  visibility:hidden;
  opacity:0;
  transition:opacity .25s linear, visibility .25s linear;
`;

const SliderContainer = styled.div`
  flex: 1;
  margin: 0 auto;
  max-width: 75.28rem;
  padding-left: 2rem;
  padding-right: 2rem;
  text-align: ${props => (props.center ? 'center' : '')};
  position: relative;

  &> div {
    position: absolute;
    will-change: transform;
  }

  &> div > div {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 100%;
    will-change: transform;
  }
`;


