import React from 'react';
import { variables } from '../utils/theme';

export const CheckboxChecked = props => (
  <svg
    height="20px"
    width="20px"
    viewBox="0 0 20 20"
    {...props}
  >
    <path fill="none" stroke={variables.colors.blue} opacity=".6" d="M.5.5h19v19H.5z" />
    <g fill={variables.colors.red}>
      <path d="M3.583 14.952L14.896 3.638l1.414 1.414L4.997 16.366z" />
      <path d="M3.583 5.052l1.414-1.414L16.31 14.952l-1.415 1.414z" />
    </g>
  </svg>
);
