import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

export const SideNavLink = ({ children, handleCloseMenu, to }) => (
  <li>
    <Link to={to} onClick={handleCloseMenu} onKeyDown={handleCloseMenu}>
      {children}
    </Link>
  </li>
);

SideNavLink.propTypes = {
  children: PropTypes.node.isRequired,
  handleCloseMenu: PropTypes.func.isRequired,
  to: PropTypes.string.isRequired,
};
