import styled from 'styled-components';

import { mq } from '../utils/helpers';

export const FooterWebsite = styled.div`
  padding-top: .5rem;

  ${mq.mq960`
    flex-direction: column;
    margin: 1rem 0 2rem;
    
    a + a {
      margin-top: .5rem;
    }
  `}
`;
