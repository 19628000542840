/* eslint-disable no-undef */
import React, { PureComponent } from 'react';
import styled from 'styled-components';

import { LvTitleLogo } from '../icons';

const StyledBrandIconWithText = styled.div`
  height: 1.875rem;
  overflow: hidden;
  width: 9.8925rem;
`;

export class BrandIconWithText extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      text: 'visible',
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    try {
      if (window.pageYOffset > 30) {
        this.setState({ text: 'hidden' });
      } else {
        this.setState({ text: 'visible' });
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }

  render() {
    const { text } = this.state;

    return (
      <StyledBrandIconWithText style={{ marginBottom: '8px' }}>
        <LvTitleLogo
          style={{ height: '1.875rem' }}
          text={text}
        />
      </StyledBrandIconWithText>
    );
  }
}
