export const MQ_SIZES = {
  mq360: 360,
  mq480: 480,
  mq600: 600,
  mq960: 960,
  mq1140: 1140,
  mq1280: 1280,
  mq1440: 1440,
  mq1920: 1920,
};

export const COLORS = {
  black: '#000',
  white: '#FFF',
  grey: '#707070',
  red: '#FF0146',
  blue: '#0043FF',
  violet: '#9E1A8C',
  purple: '#6A27B2',
  green: '#21FF2A',
};

export const DIMENSIONS = {
  buttonBorderRadius: '0',
  containerMaxWidth: '75.25rem',
  formElementWidthStandard: '31%',
  navbarHeight: '4.5rem',
  topBreath: '6rem',
};

export const PARAGRAPH_SIZES = {
  /**
   * paragraph original sizes
   * 1 - 10px
   * 2 - 12px
   * 3 - 14px
   * 4 - 16px default
   * 5 - 24px
   * 6 - 26px
   * 7 - 32px
   * */
  1: '.625rem',
  2: '.75rem',
  3: '.875rem',
  4: '1rem',
  5: '1.5rem',
  6: '1.625rem',
  7: '2rem',
};

export const Z_INDEX = {
  navbar: 10,
  chatButton: 10,
};
