import React from 'react';
import { variables } from '../utils/theme';

export const CheckboxUnchecked = props => (
  <svg
    height="20px"
    opacity=".6"
    width="20px"
    viewBox="0 0 20 20"
    {...props}
  >
    <path fill="none" stroke={variables.colors.blue} d="M.5.5h19v19H.5z" />
  </svg>
);
