import styled from 'styled-components';

export const SocialIconLink = styled.a`
  && {
    fill: ${props => props.theme.colors.white};
    transition: fill .15s;
    text-align:right;
    
    &:hover {
      fill: ${props => props.theme.colors.red};
    }
  }
`;
