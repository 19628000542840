import styled from 'styled-components';

import { mq } from '../utils/helpers';

export const GuideContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  
  ${mq.mq1140`
    align-items: center;
    flex-direction: column;
  `}
`;
