import styled from 'styled-components';

export const HeaderUpperline = styled.div`
  background: rgb(0,67,255);
  background: linear-gradient(-90deg, rgba(0,67,255,1) 0%, rgba(255,1,70,1) 100%); 
  height: 3px;
  width:100%;
  position:fixed;
  top:0;
  left:0;
`;
