import styled from 'styled-components';

import { COLORS } from '../utils/constants';
import { hexToRgba } from '../utils/helpers';


const getColor = (color) => {
  if (!Object.prototype.hasOwnProperty.call(COLORS, color)) {
    return hexToRgba(COLORS.black, 1);
  }
  return hexToRgba(COLORS[color], 1);
};

export const GradientBackground = styled.div`
  background: linear-gradient(
    ${props => props.angle}deg,
    ${props => getColor(props.firstColor)} 0,
    ${props => getColor(props.secondColor)} 60%
  );
  flex-grow: 1;
`;

GradientBackground.defaultProps = {
  firstColor: 'blue',
  secondColor: 'black',
  angle: 140,
};
