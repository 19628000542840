import React from 'react';

export const LvTitleLogo = ({ text, ...props }) => {
  return (
  <svg viewBox="0 0 160 30.325" {...props}>
    <g id="Group_119" data-name="Group 119" transform="translate(-4442.654 -468)">
      <g id="Group_118" data-name="Group 118" transform="translate(4482.293 476.655)">
        <g id="Group_117" data-name="Group 117" transform="translate(0)">
          <path visibility={text} id="Path_106" data-name="Path 106" d="M4641.62,522.914h-8.412V510.741h1.435v10.865h6.977Z" transform="translate(-4633.208 -510.505)" fill="#fff"/>
          <path visibility={text} id="Path_107" data-name="Path 107" d="M4684.387,522.914V510.741h1.435v12.173Z" transform="translate(-4673.741 -510.505)" fill="#fff"/>
          <path visibility={text} id="Path_108" data-name="Path 108" d="M4717.2,522.914h-1.235L4708,513.248v9.666h-1.435V510.741h1.236l7.958,9.775v-9.775h1.435Z" transform="translate(-4691.309 -510.505)" fill="#fff"/>
          <path visibility={text} id="Path_109" data-name="Path 109" d="M4781.176,522.914l-6.123-5.4h-.672v5.4h-1.436V510.741h1.436v5.487h.563l5.978-5.487h1.8l-6.6,6.086,7,6.087Z" transform="translate(-4743.876 -510.505)" fill="#fff"/>
          <path visibility={text} id="Path_110" data-name="Path 110" d="M4854.3,522.914h-1.381l-5.305-12.173h1.563l4.433,10.211,4.434-10.211h1.562Z" transform="translate(-4803.014 -510.505)" fill="#fff"/>
          <path visibility={text} id="Path_111" data-name="Path 111" d="M4914.516,522.914V510.741h1.435v12.173Z" transform="translate(-4855.998 -510.505)" fill="#fff"/>
          <path visibility={text} id="Path_112" data-name="Path 112" d="M4941.285,510.513a3.887,3.887,0,0,1,1.535,2.435l-1.436.327a2.751,2.751,0,0,0-1.054-1.762,3.362,3.362,0,0,0-2.107-.654,3.108,3.108,0,0,0-2,.609,1.939,1.939,0,0,0-.763,1.59,1.562,1.562,0,0,0,.463,1.208,2.81,2.81,0,0,0,1.372.608l2.653.6q3.071.654,3.071,3.161a3.19,3.19,0,0,1-1.236,2.634,5.136,5.136,0,0,1-3.289.981,5.652,5.652,0,0,1-3.353-.972,3.709,3.709,0,0,1-1.589-2.662l1.508-.309a2.877,2.877,0,0,0,1.163,1.971,3.972,3.972,0,0,0,2.4.7,3.405,3.405,0,0,0,2.171-.627,2,2,0,0,0,.79-1.644,1.6,1.6,0,0,0-.463-1.254,3.386,3.386,0,0,0-1.517-.654l-2.744-.6q-2.925-.654-2.925-3.107a3.09,3.09,0,0,1,1.19-2.525,4.827,4.827,0,0,1,3.134-.963A4.942,4.942,0,0,1,4941.285,510.513Z" transform="translate(-4871.076 -509.605)" fill="#fff"/>
          <path visibility={text} id="Path_113" data-name="Path 113" d="M5000.953,517.591a5.767,5.767,0,0,1-1.39,4.024,4.724,4.724,0,0,1-3.679,1.535,4.8,4.8,0,0,1-3.716-1.535,5.721,5.721,0,0,1-1.408-4.024v-6.85h1.436v6.85a4.423,4.423,0,0,0,1.008,3.034,3.4,3.4,0,0,0,2.68,1.145,3.328,3.328,0,0,0,2.634-1.145,4.45,4.45,0,0,0,1-3.034v-6.85h1.435Z" transform="translate(-4916.381 -510.505)" fill="#fff"/>
          <path visibility={text} id="Path_114" data-name="Path 114" d="M5056.552,522.914l-1.363-3.089h-6.414l-1.363,3.089h-1.544l5.451-12.173h1.326l5.451,12.173Zm-4.561-10.393-2.652,6.014h5.287Z" transform="translate(-4960.026 -510.505)" fill="#fff"/>
          <path visibility={text} id="Path_115" data-name="Path 115" d="M5121.618,522.914h-8.413V510.741h1.435v10.865h6.978Z" transform="translate(-5013.355 -510.505)" fill="#fff"/>
          <path visibility={text} id="Path_116" data-name="Path 116" d="M5168.534,510.513a3.886,3.886,0,0,1,1.536,2.435l-1.436.327a2.753,2.753,0,0,0-1.054-1.762,3.363,3.363,0,0,0-2.107-.654,3.108,3.108,0,0,0-2,.609,1.937,1.937,0,0,0-.763,1.59,1.561,1.561,0,0,0,.463,1.208,2.8,2.8,0,0,0,1.372.608l2.652.6q3.071.654,3.071,3.161a3.191,3.191,0,0,1-1.235,2.634,5.138,5.138,0,0,1-3.289.981,5.652,5.652,0,0,1-3.352-.972,3.707,3.707,0,0,1-1.589-2.662l1.508-.309a2.881,2.881,0,0,0,1.163,1.971,3.972,3.972,0,0,0,2.4.7,3.4,3.4,0,0,0,2.17-.627,2,2,0,0,0,.791-1.644,1.6,1.6,0,0,0-.463-1.254,3.394,3.394,0,0,0-1.517-.654l-2.744-.6q-2.925-.654-2.925-3.107a3.093,3.093,0,0,1,1.19-2.525,4.83,4.83,0,0,1,3.134-.963A4.94,4.94,0,0,1,5168.534,510.513Z" transform="translate(-5051.052 -509.605)" fill="#fff"/>
        </g>
      </g>
      <path id="Path_117" data-name="Path 117" d="M4466.884,471.744l-9.354,21.38-2.166-4.332-1.035-2.07H4446.4V471.744h20.485m5.725-3.744h-29.955v22.466h9.361l3.745,7.489h3.744L4472.609,468Z" fill="#ff0146"/>
    </g>
  </svg>
)
};
