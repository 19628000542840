import styled from 'styled-components';

import { mq } from '../utils/helpers';

export const StyledSectionLineContainer = styled.div`
  animation: horizontalMove 30s infinite linear ${props => (props.reverse ? 'reverse' : 'forwards')};
  left: 50%;
  margin-left: ${props => (props.offsetHalf ? '12.5vw' : '')};
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 150vw;
  
  ${mq.mq600`
    width: 80rem;
  `}
`;
